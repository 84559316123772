<template>
  <div>
    <!-- lead-search -->
    <div class="lead-search-area">
      <div class="row">
        <div class="col-md-3">
          <div class="lead-search sourceby">
            <div class="form-group">
              <label>Source</label>
              <multi-select
                @change="filterData"
                :searchable="true"
                id="a_usersads"
                textColumn="text"
                placeholder="Select Sources"
                v-model="filterSearch.by"
                :options="LSources"
              ></multi-select>
              <!-- <select class="form-control" id="by" v-model="filterSearch.by">
                        <option value="">Select Value</option>
                        <option v-for="source in LSources" :value="source.ls_id" :key="source.ls_id" >{{source.ls_name}}</option>
                    </select> -->
            </div>
          </div>
          <!-- <div  class="lead-search sourceby" >
                <div style="position: relative;bottom: 50px;">
                    <span>By</span>
                    <multi-select :searchable="true"  id="a_users" textColumn="text" placeholder="Select sources" v-model="filterSearch.by" :options="LSources" ></multi-select>
                </div>
            </div> -->
        </div>

        <div class="col-md-3">
          <div class="lead-search">
            <div class="form-group">
              <label>Status</label>
              <select
                class="form-control"
                style="font-weight: 400;"
                v-model="filterSearch.status"
                v-on:change="filterData"
              >
                <option value="" selected disabled hidden>Select Status</option>
                <option value="">All</option>
                <option value="2">Contacted</option>
                <option value="1">Active</option>
                <option value="3">Scheduled</option>
                <option value="4">Visit</option>
                <option value="5">Demo</option>
                <option value="6">Sold</option>
                <option value="7">Lost</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="lead-search sourceby">
            <div
              class="form-group"
              v-if="
                this.$storage.get('auth').user.user_type == 1 ||
                this.$storage.get('auth').user.permissions.indexOf('8') != -1
              "
            >
              <label>Agents</label>
              <!-- <select
                class="form-control"
                v-model="filterSearch.agent"
                v-on:change="filterData"
              >
                <option value="">Select Agent</option>
                <option
                  v-for="emp in allEmployees"
                  :key="emp.id"
                  :value="emp.id"
                >
                  {{ emp.text }}
                </option>
              </select> -->
              <SingleSelect
                v-on:change="filterData"
                :searchable="true"
                id="a_usersadss"
                textColumn="text"
                placeholder="Select Agent"
                v-model="agent"
                :alreadySelected="agent"
                :options="allEmployees"
              ></SingleSelect>
            </div>
          </div>
        </div>

        <!-- <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="lead-search">
                <div class="form-group">
                    <label for="start">Start</label>
                    <date-range-picker
                      :locale-data="{ format: 'mmmm dd, yyyy' }"
                      v-model="filterSearch.from"
                      :single-date-picker="true"
                      :show-dropdowns="true"
                      :auto-apply="true"
                      :ranges="false"
                    ></date-range-picker>
                </div>
            </div>
        </div>
        <div class="col-sm-5 col-md-5 col-lg-3">
          <div class="lead-search">
            <div class="form-group">
              <label for="end">End</label>
              <date-range-picker
                :locale-data="{ format: 'mmmm dd, yyyy' }"
                v-model="filterSearch.to"
                :single-date-picker="true"
                :show-dropdowns="true"
                :auto-apply="true"
                :ranges="false"
              ></date-range-picker>
            </div>
          </div>
        </div> -->
        <div class="col-sm-3">
          <div class="lead-search">
            <a class="refresh" @click="clearState()" href="/leads"
              ><img
                class="img-fluid"
                src="../../assets/img/refresh.svg"
                alt=""
              />
              Reset</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- lead-search -->

    <div class="timeline col-md-12 text-right">
      <button
        class="delbtn"
        style="background: #fdeeee; color: red"
        type="button"
        v-on:click="deleteLeadsModal"
      >
        <span class="today">Delete Leads</span>
      </button>

      <button
        type="button"
        v-bind:class="$store.state.leadFilter == 'today' ? 'current' : ''"
        v-on:click="updateData('today')"
      >
        <span class="today">Today</span>
      </button>
      <button
        type="button"
        v-bind:class="$store.state.leadFilter == 'month' ? 'current' : ''"
        v-on:click="updateData('month')"
      >
        <span class="today">This Month</span>
      </button>
      <button
        type="button"
        v-bind:class="$store.state.leadFilter == 'custom' ? 'current' : ''"
      >
        <date-range-picker
          :opens="'left'"
          :ranges="false"
          :locale-data="{ format: 'yyyy-mm-dd' }"
          v-model="filterSearch.customDate"
          @update="updateData('custom')"
        ></date-range-picker
        ><span class="month">Custom Date</span>
      </button>
    </div>
    <!-- lead-boxes -->
    <div class="leads-boxes">
      <router-link
        :to="{ path: '/leads' }"
        class="leads-box"
        :class="
          $route.query.type
            ? ''
            : $route.query.keyword
            ? ''
            : $route.query.status
            ? ''
            : 'active'
        "
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.27815 4.4003L1.70199 4.88044L1.70199 4.88044L2.27815 4.4003ZM7.94716 11.2031L7.37099 11.6832L7.94716 11.2031ZM9.00012 17.1667L9.45012 16.5667L9.00012 17.1667ZM10.3335 18.1667L10.7835 17.5667L10.7835 17.5667L10.3335 18.1667ZM12.0531 11.2031L12.6293 11.6832V11.6832L12.0531 11.2031ZM17.7221 4.4003L18.2983 4.88043V4.88043L17.7221 4.4003ZM1.70199 4.88044L7.37099 11.6832L8.52332 10.723L2.85432 3.92016L1.70199 4.88044ZM7.58345 12.2701V15.8333H9.08345V12.2701H7.58345ZM8.55012 17.7667L9.88345 18.7667L10.7835 17.5667L9.45012 16.5667L8.55012 17.7667ZM12.4168 17.5V12.2701H10.9168V17.5H12.4168ZM12.6293 11.6832L18.2983 4.88043L17.1459 3.92016L11.4769 10.723L12.6293 11.6832ZM16.4417 0.916656H3.55852V2.41666H16.4417V0.916656ZM18.2983 4.88043C19.61 3.30639 18.4907 0.916656 16.4417 0.916656V2.41666C17.2189 2.41666 17.6435 3.32311 17.1459 3.92016L18.2983 4.88043ZM12.4168 12.2701C12.4168 12.0556 12.492 11.848 12.6293 11.6832L11.4769 10.723C11.115 11.1573 10.9168 11.7047 10.9168 12.2701H12.4168ZM9.88345 18.7667C10.9272 19.5495 12.4168 18.8047 12.4168 17.5H10.9168C10.9168 17.5249 10.9108 17.537 10.906 17.5444C10.8995 17.5544 10.8879 17.5659 10.8707 17.5745C10.8535 17.5831 10.8373 17.5855 10.8255 17.5847C10.8167 17.5841 10.8034 17.5816 10.7835 17.5667L9.88345 18.7667ZM7.58345 15.8333C7.58345 16.594 7.94159 17.3103 8.55012 17.7667L9.45012 16.5667C9.2193 16.3935 9.08345 16.1219 9.08345 15.8333H7.58345ZM7.37099 11.6832C7.50827 11.848 7.58345 12.0556 7.58345 12.2701H9.08345C9.08345 11.7047 8.88525 11.1573 8.52332 10.723L7.37099 11.6832ZM2.85432 3.92016C2.35678 3.32311 2.78134 2.41666 3.55852 2.41666V0.916656C1.50958 0.916656 0.390289 3.3064 1.70199 4.88044L2.85432 3.92016Z"
              fill="#A2A8B8"
            />
          </svg>
          {{ leadsCounter.allleads }}
        </h3>
        <p>All Leads</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: 'active' } }"
        class="leads-box"
        :class="$route.query.type == 'active' ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M2.27815 4.4003L1.70199 4.88044L1.70199 4.88044L2.27815 4.4003ZM7.94716 11.2031L7.37099 11.6832L7.94716 11.2031ZM9.00012 17.1667L9.45012 16.5667L9.00012 17.1667ZM10.3335 18.1667L10.7835 17.5667L10.7835 17.5667L10.3335 18.1667ZM12.0531 11.2031L12.6293 11.6832V11.6832L12.0531 11.2031ZM17.7221 4.4003L18.2983 4.88043V4.88043L17.7221 4.4003ZM1.70199 4.88044L7.37099 11.6832L8.52332 10.723L2.85432 3.92016L1.70199 4.88044ZM7.58345 12.2701V15.8333H9.08345V12.2701H7.58345ZM8.55012 17.7667L9.88345 18.7667L10.7835 17.5667L9.45012 16.5667L8.55012 17.7667ZM12.4168 17.5V12.2701H10.9168V17.5H12.4168ZM12.6293 11.6832L18.2983 4.88043L17.1459 3.92016L11.4769 10.723L12.6293 11.6832ZM16.4417 0.916656H3.55852V2.41666H16.4417V0.916656ZM18.2983 4.88043C19.61 3.30639 18.4907 0.916656 16.4417 0.916656V2.41666C17.2189 2.41666 17.6435 3.32311 17.1459 3.92016L18.2983 4.88043ZM12.4168 12.2701C12.4168 12.0556 12.492 11.848 12.6293 11.6832L11.4769 10.723C11.115 11.1573 10.9168 11.7047 10.9168 12.2701H12.4168ZM9.88345 18.7667C10.9272 19.5495 12.4168 18.8047 12.4168 17.5H10.9168C10.9168 17.5249 10.9108 17.537 10.906 17.5444C10.8995 17.5544 10.8879 17.5659 10.8707 17.5745C10.8535 17.5831 10.8373 17.5855 10.8255 17.5847C10.8167 17.5841 10.8034 17.5816 10.7835 17.5667L9.88345 18.7667ZM7.58345 15.8333C7.58345 16.594 7.94159 17.3103 8.55012 17.7667L9.45012 16.5667C9.2193 16.3935 9.08345 16.1219 9.08345 15.8333H7.58345ZM7.37099 11.6832C7.50827 11.848 7.58345 12.0556 7.58345 12.2701H9.08345C9.08345 11.7047 8.88525 11.1573 8.52332 10.723L7.37099 11.6832ZM2.85432 3.92016C2.35678 3.32311 2.78134 2.41666 3.55852 2.41666V0.916656C1.50958 0.916656 0.390289 3.3064 1.70199 4.88044L2.85432 3.92016Z"
              fill="#A2A8B8"
            />
          </svg>
          {{ leadsCounter.active }}
        </h3>
        <p>Total Leads & Ups</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: '1' } }"
        class="leads-box"
        :class="$route.query.type == 1 ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833"
              stroke="#A2A8B8"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.floor_up }}
        </h3>
        <p>Floor Ups</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: '2' } }"
        class="leads-box"
        :class="$route.query.type == 2 ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.3332 1.66666C16.0947 1.66666 18.3332 3.90523 18.3332 6.66666M13.3332 4.99999C14.2537 4.99999 14.9999 5.74618 14.9999 6.66666M16.6689 18.2513C8.793 17.4704 2.52949 11.2069 1.74863 3.33099C1.65782 2.41501 2.41276 1.66666 3.33324 1.66666H6.29864C7.01602 1.66666 7.65292 2.12571 7.87978 2.80628L8.84084 5.68946C9.04047 6.28835 8.8846 6.94863 8.43821 7.39502L7.96674 7.86649C7.69323 8.14 7.64208 8.56751 7.85608 8.88971C8.77041 10.2664 9.60932 11.1035 11.105 12.1266C11.4295 12.3486 11.8653 12.3012 12.1434 12.0232L12.6049 11.5617C13.0513 11.1153 13.7115 10.9594 14.3104 11.1591L17.1936 12.1201C17.8742 12.347 18.3332 12.9839 18.3332 13.7013V16.6667C18.3332 17.5871 17.5849 18.3421 16.6689 18.2513Z"
              stroke="#A2A8B8"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.phone_up }}
        </h3>
        <p>Phone Ups</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: '3' } }"
        class="leads-box"
        :class="$route.query.type == 3 ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M19.1668 10C19.1668 15.0626 15.0628 19.1667 10.0002 19.1667M19.1668 10C19.1668 4.9374 15.0628 0.833344 10.0002 0.833344M19.1668 10H0.833496M10.0002 19.1667C4.93755 19.1667 0.833496 15.0626 0.833496 10M10.0002 19.1667C11.8411 19.1667 13.3335 15.0626 13.3335 10C13.3335 4.9374 11.8411 0.833344 10.0002 0.833344M10.0002 19.1667C8.15921 19.1667 6.66683 15.0626 6.66683 10C6.66683 4.9374 8.15921 0.833344 10.0002 0.833344M0.833496 10C0.833496 4.9374 4.93755 0.833344 10.0002 0.833344"
              stroke="#A2A8B8"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.internet_up }}
        </h3>
        <p>Internet Leads</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: 'untouched' } }"
        class="leads-box"
        :class="$route.query.type == 'untouched' ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M22.3257 11.0497C22.7739 11.6143 22.7739 12.3857 22.3257 12.9503C20.9829 14.642 17.6107 18 11.9999 18C6.38916 18 3.01702 14.642 1.67418 12.9503C1.22596 12.3857 1.22596 11.6143 1.67418 11.0497C3.01702 9.35796 6.38916 6 11.9999 6C17.6107 6 20.9829 9.35796 22.3257 11.0497Z"
              stroke="#A2A8B8"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M14.9999 12C14.9999 13.6569 13.6568 15 11.9999 15C10.3431 15 8.99994 13.6569 8.99994 12C8.99994 10.3431 10.3431 9 11.9999 9C13.6568 9 14.9999 10.3431 14.9999 12Z"
              stroke="#A2A8B8"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.untouched }}
        </h3>
        <p>Untouched Internet Leads</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: 'sold' } }"
        class="leads-box"
        :class="$route.query.type == 'sold' ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00011 9C5.11073 9 1.91284 11.9606 1.53703 15.7511C1.49616 16.1632 1.83589 16.5 2.2501 16.5H15.7501C16.1643 16.5 16.504 16.1632 16.4632 15.7511C16.0874 11.9606 12.8895 9 9.00011 9ZM9.00011 9C11.0712 9 12.7501 7.32107 12.7501 5.25C12.7501 3.17893 11.0712 1.5 9.00011 1.5C6.92904 1.5 5.2501 3.17893 5.2501 5.25C5.2501 7.32107 6.92904 9 9.00011 9Z"
              stroke="#DBE5FF"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.solds }}
        </h3>
        <p>Sold Leads</p>
      </router-link>
      <router-link
        :to="{ path: '/leads', query: { type: 'lost' } }"
        class="leads-box"
        :class="$route.query.type == 'lost' ? 'active' : ''"
      >
        <h3>
          <svg
            class="icons"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M9.00011 9C5.11073 9 1.91284 11.9606 1.53703 15.7511C1.49616 16.1632 1.83589 16.5 2.2501 16.5H15.7501C16.1643 16.5 16.504 16.1632 16.4632 15.7511C16.0874 11.9606 12.8895 9 9.00011 9ZM9.00011 9C11.0712 9 12.7501 7.32107 12.7501 5.25C12.7501 3.17893 11.0712 1.5 9.00011 1.5C6.92904 1.5 5.2501 3.17893 5.2501 5.25C5.2501 7.32107 6.92904 9 9.00011 9Z"
              stroke="#DBE5FF"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
          {{ leadsCounter.losts }}
        </h3>
        <p>Lost Deals</p>
      </router-link>
    </div>
    <!-- lead-boxes -->

    <!-- leads-table-area  -->
    <div class="leads-table-area addcustomer-table">
      <table id="leads_table" class="display">
        <thead>
          <tr>
            <th class="All">&nbsp;</th>
            <th>ID</th>
            <th>Created Date</th>
            <th>Customer Name</th>
            <th>Sales Rep</th>
            <th>BDC Rep</th>
            <th width="20">Vehicle</th>
            <th>Type</th>
            <th>Source</th>
            <th>Engagements</th>
            <!-- <th>Be back</th> -->
            <th>Last Engaged</th>
            <th>Future Task</th>
            <th>Status</th>
            <!-- <th>Active Leads</th>
            <th>Previous Leads</th> -->
          </tr>
        </thead>
        <tbody>
          <!-- <tr>
              <td>
                  <label class="check2">
                  <input type="checkbox">
                  <span class="checkmarks"></span>
                  </label>
              </td>
              <td>1/5/20</td>
              <td><a href="lead-details.html"><img class="img-fluid" src="../../assets/img/Ellipse_51.svg" alt=""> Sion Doul </a></td>
              <td>56</td>
              <td>
                  <a class="assin" href="#">Assigned</a>
              </td>
              <td>Norma Fox</td>
              <td>Delaer Website</td>
              <td>9/30/13 1:36 AM</td>
              <td>(843) 555-0130</td>
          </tr> -->
        </tbody>
      </table>
    </div>
    <!-- leads-table-area  -->

    <!-- previous Leadsheets Modal -->
    <div
      class="modal fade"
      id="previousLeads"
      tabindex="-1"
      role="dialog"
      aria-labelledby="previousLeadsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="previousLeadsLabel">Leadsheets</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="customer-modal">
              <table
                class="table table-borderless table-hover previous-leads-tbl"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Date Modified</th>
                    <th>Agent</th>
                    <th>Vehicle</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="plead in prev_leads" :key="plead.l_id">
                    <td>
                      <a class="idtable" :href="'lead-detail/' + plead.l_id">{{
                        plead.l_id
                      }}</a>
                    </td>
                    <td>{{ plead.modified_date }}</td>
                    <td>{{ plead.agent }}</td>
                    <td>{{ plead.v_make }}</td>
                    <td>
                      <span v-if="plead.l_status == 1">Active</span>
                      <span v-if="plead.l_status == 2">Contacted</span>
                      <span v-if="plead.l_status == 3">Scheduled</span>
                      <span v-if="plead.l_status == 4">Visit</span>
                      <span v-if="plead.l_status == 5">Demo</span>
                      <span v-if="plead.l_status == 6">Sold</span>
                      <span v-if="plead.l_status == 7">Lost</span>
                    </td>
                  </tr>
                  <tr v-if="prev_leads.length == 0">
                    <td colspan="5" class="not-found">No lead found</td>
                  </tr>
                  <!-- <tr>
                                        <td><a class="idtable" href="#">485469</a></td>
                                        <td>3/21/2019</td>
                                        <td>Arthur</td>
                                        <td>BMW</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td><a class="idtable" href="#">485469</a></td>
                                        <td>3/21/2019</td>
                                        <td>Arthur</td>
                                        <td>BMW</td>
                                        <td>Active</td>
                                    </tr>
                                    <tr>
                                        <td><a class="idtable" href="#">485469</a></td>
                                        <td>3/21/2019</td>
                                        <td>Arthur</td>
                                        <td>BMW</td>
                                        <td>Active</td>
                                    </tr> -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- leads-assign area  -->
    <div class="modal fade" id="leadAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Assign Lead</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignToUser">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <!-- <label for="inputEmail4">Users</label> -->
                    <multi-select
                      :searchable="true"
                      id="a_users"
                      :alreadySelected="assign.to"
                      textColumn="text"
                      placeholder="Select Users"
                      v-model="assign.to"
                      :options="allEmployees.filter((emp) => emp.text != 'All')"
                      label="Users"
                    ></multi-select>

                    <!-- <Select2 v-model="assign.to" :options="allEmployees" :settings="{placeholder: 'Select user'}" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Assign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / leads-assign area  -->

    <!-- leads-assign area  -->
    <div class="modal fade" id="BDCleadAssign" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Assign Lead</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <form v-on:submit.prevent="assignToUser">
            <div class="modal-body">
              <div class="add-member add-lead">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <!-- <label for="inputEmail4">Users</label> -->
                    <multi-select
                      :searchable="true"
                      id="bdc_users"
                      :alreadySelected="assign.to"
                      textColumn="text"
                      placeholder="Select Users"
                      v-model="assign.to"
                      :options="allBDCEmployees.filter((emp) => emp.text != 'All')"
                      label="Users"
                    ></multi-select>

                    <!-- <Select2 v-model="assign.to" :options="allEmployees" :settings="{placeholder: 'Select user'}" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <a href="#" class="add-btn" data-dismiss="modal"
                ><img
                  class="img-fluid"
                  src="../../assets/img/22. Delete.svg"
                  alt=""
                />
                Cancel</a
              >
              <button type="submit" class="rem-btn">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Close.svg"
                  alt=""
                />
                Assign
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- / leads-assign area  -->

    <!-- leadsheet attension modal-->
    <div
      class="modal fade"
      id="deleteleadsheetPermision"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 20px">
              {{ leadids.length }} {{ "Lead" | pluralize(leadids.length) }} will
              be deleted permanently. This can not be undone. Do you wish to
              proceed?
            </p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              class="rem-btn close"
              @click="deleteLeads"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <input type="hidden" :value="$route.query.type" id="lead_type" />
    <input type="hidden" :value="$route.query.source" id="source" />
    <input type="hidden" :value="$route.query.status" id="lead_status" />
    <input type="hidden" :value="$route.query.duration" id="lead_duration" />
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from "moment";
//import Select2 from 'v-select2-component';
import SingleSelect from "../helpers/singleSelect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

import MultiSelect from "../helpers/MultiSelect";

export default {
  name: "LeadsGrid",
  components: { DateRangePicker, MultiSelect, SingleSelect },
  data() {
    return {
      delLoader: false,
      leadids: [],
      agent: {
        id : "",
        text : "Select Agent",
      },
      leads: null,
      filterSearch: {
        by: [],
        from: {},
        to: {},
        period: "",
        agent: "",
        team: "",
        customDate: {},
        status: "",
        defaultstatus: "",
      },
      allEmployees: [],
      allBDCEmployees:[],
      assign: {
        to: [],
        lead_id: 0,
        alreadyAssigned: this.alreadyAssignedUsers,
      },
      activeTab: "",
      leadsCounter: {},
      LSources: [],
      clickCount: 0,
      prev_leads: [],
      alreadyAssignedUsers: [],
    };
  },
  created() {
    this.filterSearch.agent = this.$route.query.agent;
    this.getAllEmployees();
    this.getAllBDCRep();
    //this.getLeadsCount();
    this.getLeadSource();
    this.$root.$on("newLead", function (action) {
      if (action == "added") {
        $("#leads_table").dataTable().fnDraw();
      }
    });
  },
  watch: {
    lead_status: function (newVal) {
      this.current_status = newVal;
      if (newVal == 6 || newVal == 7) {
        this.visit = "desk";
      } else {
        this.visit = newVal;
      }
    },
    "$route.query.type": function () {
      $("#lead_type").val(this.$route.query.type);
      $("#lead_status").val(this.$route.query.status);
      $("#leads_table").dataTable().fnDraw();
    },
  },
  methods: {
    deleteLeadsModal() {
      if (
        this.$storage.get("auth").user.user_type == 1 ||
        this.$storage.get("auth").user.role_id == 4 ||
        this.$storage.get("auth").user.role_id == 8 ||
        this.$storage.get("auth").user.role_id == 5 ||
        this.$storage.get("auth").user.role_id == 7 ||
        this.$storage.get("auth").user.role_id == 10 ||
        this.$storage.get("auth").user.permissions.indexOf("7") != -1
      ) {
        if (this.leadids.length > 0) {
          $("#deleteleadsheetPermision").modal("show");
        } else {
          this.$toast.open({
            position: "top-right",
            message: "No Lead Selected",
            type: "info",
          });
        }
      } else {
        this.$toast.open({
          position: "top-right",
          message: "You do not have permissions to delete leads",
          type: "info",
        });
      }
    },
    deleteLeads() {
      if (this.leadids.length > 0) {
        axios
          .get("leadsheetdelete", {
            params: {
              data: this.leadids,
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              if (res.data.success == true) {
                this.leadids = [];
                $("#deleteleadsheetPermision").modal("hide");
                $("#leads_table").dataTable().fnDraw();
                this.$toast.open({
                  position: "top-right",
                  message: res.data.message,
                  type: "success",
                });
              }

              console.log(res.data.success);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$toast.open({
          position: "top-right",
          message: "No Lead Selected",
          type: "info",
        });
      }
    },
    updateData(val) {
      this.leadids = [];
      if (val == "custom") {
        let startDate = this.filterSearch.customDate.startDate;
        let endDate = this.filterSearch.customDate.endDate;
        this.$store.state.lead_from = moment(new Date(startDate)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.lead_to = moment(new Date(endDate)).format(
          "YYYY-MM-DD"
        );

        this.$store.commit("updateLeadFilter", "custom");
      } else {
        this.$store.commit("updateLeadFilter", val);
      }
      //alert(this.filterSearch.period)
      $("#leads_table").dataTable().fnDraw();
    },
    previousLeads(id) {
      axios
        .get("previous_leads/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            //console.log(res.data.success)
            this.prev_leads = res.data.success;
            // this.assign = {to:[],lead_id:0}
            // $('#leads_table').dataTable().fnDraw();
            // $("#leadAssign").modal('hide')
            // if (res.data.noti_emails.length > 0) {
            //     let vm = this;
            //     $.each(res.data.noti_emails, function(key, value) {
            //         vm.$socket.emit('send_noti', {receiver:value})
            //     });
            // }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterData() {
      this.leadids = [];
      this.filterSearch.agent = this.agent.id;
      $("#leads_table").dataTable().fnDraw();
      this.getLeadsCount();
      //console.log(this.filterSearch)

      // if (
      //   this.filterSearch.from.startDate != undefined &&
      //   this.filterSearch.from.startDate != "" &&
      //   this.filterSearch.from.startDate != "undefined" &&
      //   this.filterSearch.from.startDate != null &&
      //   this.filterSearch.to.startDate != undefined &&
      //   this.filterSearch.to.startDate != "" &&
      //   this.filterSearch.to.startDate != "undefined" &&
      //   this.filterSearch.to.startDate != null
      // ) {
      //   if (
      //     new Date(this.filterSearch.from.startDate) <
      //     new Date(this.filterSearch.to.startDate)
      //   ) {
      //     $("#leads_table").dataTable().fnDraw();
      //   } else {
      //     alert("Start date must be less than End date.");
      //   }
      // } else {
      //   $("#leads_table").dataTable().fnDraw();
      // }
    },
    assignToUser() {
      //console.log(this.assign.to);
      this.assign.alreadyAssigned = this.alreadyAssignedUsers;
      axios
        .post("lead_assign", this.assign, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.alreadyAssignedUsers = res.data.new_assigned;
            this.assign.to = [];
            $("#leads_table").dataTable().fnDraw();
            $("#leadAssign").modal("hide");
            $("#BDCleadAssign").modal("hide");
            if (res.data.noti_emails.length > 0) {
              let vm = this;
              $.each(res.data.noti_emails, function (key, value) {
                vm.$socket.emit("send_noti", { receiver: value });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllEmployees() {
      axios
        .get("allnonbdcusers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success.length > 0) {
              var dd = [];
              dd.push({
                id: "",
                text: "All",
              });
              $.each(res.data.success, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.name,
                });
              });
              this.allEmployees = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllBDCRep(){
      axios
        .get("allbdcusers", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success.length > 0) {
              var dd = [];
              dd.push({
                id: "",
                text: "All",
              });
              $.each(res.data.success, function (key, value) {
                dd.push({
                  id: value.id,
                  text: value.name,
                });
              });
              this.allBDCEmployees = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadsCount() {
      this.filterSearch.source = "";
      if (this.$route.query.duration) {
        this.filterSearch.period = $("#lead_duration").val();
      }
      if (this.$route.query.source) {
        this.filterSearch.source = this.$route.query.source;
      }
      this.filterSearch.datefrom = this.$store.state.lead_from;
      this.filterSearch.dateto = this.$store.state.lead_to;
      this.filterSearch.period = this.$store.state.leadFilter;
      axios
        .post("leads_count", this.filterSearch, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.leadsCounter = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearState(){
      localStorage.removeItem('DataTables_leads_table_/leads');
    },
    getLeadSource() {
      axios
        .get("dealer/lead/sources", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.success.length > 0) {
              var dd = [];
              $.each(res.data.success, function (key, value) {
                dd.push({ id: value.ls_id, text: value.ls_name });
              });
              this.LSources = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.$store.commit("resetAllFilters");
    this.filterSearch.defaultstatus == "all";
    if (this.$route.query.filter) {
      if (this.$route.query.filter == "monthly") {
        this.filterSearch.period = "month";
        this.$store.commit("updateLeadFilter", "month");
      } else if (this.$route.query.filter == "today") {
        this.filterSearch.period = "today";
        this.$store.commit("updateLeadFilter", "today");
      } else if (this.$route.query.filter == "custom") {
        this.filterSearch.period = "custom";
        this.filterSearch.datefrom = this.$route.query.from;
        this.filterSearch.dateto = this.$route.query.to;
        this.$store.state.lead_from = moment(new Date(this.$route.query.from)).format(
          "YYYY-MM-DD"
        );
        this.$store.state.lead_to = moment(new Date(this.$route.query.to)).format(
          "YYYY-MM-DD"
        );
        this.$store.commit("updateLeadFilter", "custom");
      }
    }

    let v = this;
    $("#leads_table")
      .addClass("nowrap")
      .dataTable({
        createdRow: function (row, data) {
          if (data[12] == 0) {
            $(row).addClass("rowBackground");
          }
        },
        order: [[1, "desc"]],

        responsive: true,
        fixedHeader: true,
        processing: true,
        serverSide: true,
        autoWidth: false,
        stateSave: true,
        ajax: {
          url: axios.defaults.baseURL + "dt_leads",
          type: "POST",
          data: function (d) {
            //console.log(v.filterSearch)
            if (
              v.filterSearch.from.startDate != undefined &&
              v.filterSearch.from.startDate != "" &&
              v.filterSearch.from.startDate != "undefined" &&
              v.filterSearch.from.startDate != null &&
              v.filterSearch.to.startDate != undefined &&
              v.filterSearch.to.startDate != "" &&
              v.filterSearch.to.startDate != "undefined" &&
              v.filterSearch.to.startDate != null
            ) {
              d.from =
                new Date(v.filterSearch.from.startDate).getFullYear() +
                "-" +
                (new Date(v.filterSearch.from.startDate).getMonth() + 1) +
                "-" +
                new Date(v.filterSearch.from.startDate).getDate();
              d.to =
                new Date(v.filterSearch.to.startDate).getFullYear() +
                "-" +
                (new Date(v.filterSearch.to.startDate).getMonth() + 1) +
                "-" +
                new Date(v.filterSearch.to.startDate).getDate();
            }
            d.by = v.filterSearch.by;
            d.type = $("#lead_type").val();
            d.status = $("#lead_status").val();
            d.duration = $("#lead_duration").val();
            d.keyword = v.$route.query.keyword;
            d.period = v.$store.state.leadFilter;
            d.datefrom = v.$store.state.lead_from;
            d.dateto = v.$store.state.lead_to;
            d.leadstatus = v.filterSearch.status;
            d.defaultstatus = v.filterSearch.defaultstatus;
            d.agent = v.filterSearch.agent;
            d.source = $("#source").val();
            d.leadids = v.leadids.join(",");
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        },
        stateSaveParams: function (s, d) {
          console.log(s);
          if (
              v.filterSearch.from.startDate != undefined &&
              v.filterSearch.from.startDate != "" &&
              v.filterSearch.from.startDate != "undefined" &&
              v.filterSearch.from.startDate != null &&
              v.filterSearch.to.startDate != undefined &&
              v.filterSearch.to.startDate != "" &&
              v.filterSearch.to.startDate != "undefined" &&
              v.filterSearch.to.startDate != null
            ) {
              d.from =
                new Date(v.filterSearch.from.startDate).getFullYear() +
                "-" +
                (new Date(v.filterSearch.from.startDate).getMonth() + 1) +
                "-" +
                new Date(v.filterSearch.from.startDate).getDate();
              d.to =
                new Date(v.filterSearch.to.startDate).getFullYear() +
                "-" +
                (new Date(v.filterSearch.to.startDate).getMonth() + 1) +
                "-" +
                new Date(v.filterSearch.to.startDate).getDate();
            }
            d.by = v.filterSearch.by;
            d.type = $("#lead_type").val();
            d.status = $("#lead_status").val();
            d.duration = $("#lead_duration").val();
            d.keyword = v.$route.query.keyword;
            d.period = v.$store.state.leadFilter;
            d.datefrom = v.$store.state.lead_from;
            d.dateto = v.$store.state.lead_to;
            d.leadstatus = v.filterSearch.status;
            d.defaultstatus = v.filterSearch.defaultstatus;
            d.agent = v.filterSearch.agent;
            d.source = $("#source").val();
            d.leadids = v.leadids.join(",");
        },
        stateLoadParams: function (s,d) {
            console.log(s);
            // Restore values into Vue variables and input fields if they exist in `d`
            if (d.from && d.to) {
              v.filterSearch.from.startDate = new Date(d.from);
              v.filterSearch.to.startDate = new Date(d.to);
            }

            v.filterSearch.by = d.by || "";
            v.filterSearch.status = d.leadstatus || "";
            v.filterSearch.defaultstatus = d.defaultstatus || "";
            v.filterSearch.agent = d.agent || "";
            v.$store.state.leadFilter = d.period || "";
            v.$store.state.lead_from = d.datefrom || "";
            v.$store.state.lead_to = d.dateto || "";
            v.leadids = d.leadids ? d.leadids.split(",") : [];

            // Restore values for jQuery-based fields
            $("#lead_type").val(d.type || "").trigger("change");
            $("#lead_status").val(d.status || "").trigger("change");
            $("#lead_duration").val(d.duration || "").trigger("change");
            $("#source").val(d.source || "").trigger("change");
        },
        drawCallback: function () {
          //alert( 'Table redrawn '+new Date() );
          v.getLeadsCount();
        },
        columnDefs: [
          {
            targets: [0, 3, 4],
            orderable: false,
          },
          {
            targets: 8,
            className: "first eng-center",
          },
        ],
        //columnDefs: [{ targets: [-1, -3], className: 'dt-body-right' }]
      });
    let vm = this;
    $("tbody").on("click", ".assin-to", function () {
      //vm.assign.to =[]
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        vm.assign.lead_id = id;
      } else {
        vm.assign.lead_id = 0;
      }

      var aignedusers = $(this).data("aignedusers");
      vm.alreadyAssignedUsers = [];
      if (
        aignedusers !== undefined &&
        aignedusers != "undefined" &&
        aignedusers != null &&
        aignedusers != ""
      ) {
        var au = aignedusers.split(",");
        if (
          au !== undefined &&
          au != "undefined" &&
          au != null &&
          au != "" &&
          au.length > 0
        ) {
          vm.assign.to.splice(0);
          $.each(au, function (i, v) {
            var aua = v.split("|");
            if (aua) {
              vm.alreadyAssignedUsers.push({ id: aua[0], text: aua[1] });
              vm.assign.to.push({ id: aua[0], text: aua[1] });
            }
          });
        }
      }
    });
    $("tbody").on("click", ".manname", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        v.$router.push("/lead-detail/" + id);
      }
    });
    $("tbody").on("click", ".prev-leads", function () {
      var id = $(this).data("id");
      if (id !== undefined && id != "undefined" && id != null && id != "") {
        vm.previousLeads(id);
      }
    });

    $("tbody").on("click", ".delete_check", function (event) {
      var id = $(this).data("id");
      if (event.target.checked) {
        // alert(id);
        vm.leadids.push(id);
      } else {
        var index = vm.leadids.indexOf(id);
        if (index !== -1) {
          vm.leadids.splice(index, 1);
        }
      }
    });
  },
};
</script>
<style>
.sourceby .customMS {
  width: 100%;
  margin-left: 15px;
}
.sourceby .customMS-drop h4 {
  border-radius: 30px;
  font-size: 14px;
  border: none;
  box-shadow: 0px 4px 8px rgba(49, 81, 161, 0.08);
}
.sourceby .show-options {
  top: 33px !important;
}
.sourceby .customMS-search input {
  margin: 0;
}
.leads-boxes {
  display: flex;
}
@media (max-width: 767px) {
  .leads-boxes {
    display: block;
  }
}
</style>
<style scoped>
@import "../../assets/css/dataTables.css";
.lead-search .vue-daterange-picker {
  width: 100%;
}
.vue-daterange-picker {
  min-width: auto;
}

.timeline .delbtn:hover span {
  color: red;
}
</style>
